import Image from '@leuven2030/framework/Image/Image';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import 'twin.macro';
import Card from '@leuven2030/framework/Card/Card';
import CardImage from '@leuven2030/framework/Card/CardImage';

const ContentHighlightItem = ({
  highlight_title,
  highlight_image,
  highlight_action_link
}) => (
  <Card prismicMeta={highlight_action_link._meta}>
    <div tw="flex flex-col justify-between h-full ">
      <div tw=" flex-1  border-b flex flex-col">
        <CardImage src={highlight_image.url} />
        <div tw="flex-1 justify-center flex flex-col text-center px-3 py-4">
          <h3 tw=" text-gray-600 ">{highlight_title}</h3>
        </div>
      </div>
      <div
        tw="bg-white px-3 py-6 text-primary-600 space-x-3 justify-center uppercase text-left flex items-center"
        color="primary"
      >
        <span tw="font-bold">{highlight_action_link.title}</span>
        <ArrowForwardIcon tw="text-primary-600 flex-shrink-0 w-4 h-4" />
      </div>
    </div>
  </Card>
);
export default ContentHighlightItem;
