import Link from 'next/link';
import Button from '@leuven2030/framework/Button/Button';
import React, { useContext } from 'react';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';

const NewsButton = () => {
  const {
    linkResolver,
    prismic: {
      layout: { read_more },
      news_page: { _meta }
    }
  } = useContext(PageContext);

  return (
    <Link href={linkResolver(_meta)}>
      <Button
        variant="contained"
        tw="mx-auto bg-primary-50 text-primary-400 border-transparent hover:bg-primary-900"
      >
        {read_more}
      </Button>
    </Link>
  );
};

export default NewsButton;
