import 'twin.macro';

import NewsCardGrid from '@leuven2030/framework/News/NewsCardGrid';
import NewsHeader from '@leuven2030/framework/News/NewsHeader';
import { StyleContent } from '@leuven2030/framework/styles/general';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import NewsButton from '@leuven2030/framework/News/NewsButton';

const NewsSection = (props) => {
  const {
    prismic: {
      allNews_articles: { edges }
    }
  } = useContext(PageContext);

  const filteredEdges = edges.slice(0, 3);
  return (
    <div {...props}>
      <div css={StyleContent} tw="space-y-12">
        <NewsHeader />
        <NewsCardGrid
          edges={filteredEdges}
          cardOptions={{
            size: 'small'
          }}
        />
        <NewsButton />
      </div>
    </div>
  );
};

export default NewsSection;
