import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';
import React, { useContext } from 'react';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import Button from '@leuven2030/framework/Button/Button';
import { Parallax } from 'react-parallax';
import ImageURL from '@leuven2030/framework/Image/ImageURL';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import {
  StyleContent,
  StyleParallaxCover
} from '@leuven2030/framework/styles/general';
import { css } from '@emotion/react';
const ContentHero = () => {
  const {
    prismic: {
      homepage: { banner_heading, banner_actions, banner_image }
    }
  } = useContext(PageContext);
  const height = 340;
  const strength = 300;

  const bgImage = ImageURL(banner_image.url, {
    fit: 'crop',
    w: 1500,
    h: 1000,
    crop: 'focalpoint',
    blend: '8c777777',
    'blend-mode': 'normal'
  });

  return (
    <Parallax
      css={StyleParallaxCover}
      strength={strength}
      tw="bg-gray-400"
      bgImage={bgImage}
    >
      <div>
        <div
          style={{ height }}
          tw="flex flex-col space-y-12  items-center justify-center  text-center   z-10  lg:(text-left items-start)"
          className="dark"
          css={StyleContent}
        >
          <div>
            <PrismicText
              tw=" prose dark:prose-dark max-w-full space-y-2"
              css={css`
                h1 {
                  margin: 0 !important;
                }
              `}
              text={banner_heading}
            />
          </div>
          <div tw="flex space-x-3">
            {banner_actions.map(
              (
                { banner_action_label, banner_action_link: { _meta, url } },
                index
              ) => (
                <NavigationLink url={url} prismicMeta={_meta} key={index}>
                  <Button
                    tw="bg-white"
                    size="large"
                    variant="contained"
                    key={banner_action_label}
                  >
                    {banner_action_label}
                  </Button>
                </NavigationLink>
              )
            )}
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default ContentHero;
