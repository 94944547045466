import 'twin.macro';
import StoryCard from '@leuven2030/framework/Story/StoryCard';

const StorySection = () => {
  const enabled = false;
  if (!enabled) {
    return <></>;
  }
  return (
    <div tw="bg-gray-200 py-16 relative">
      <div tw="absolute inset-0">
        <div tw="bg-gray-50 h-2/6 sm:h-5/6"></div>
      </div>
      <div tw="container relative">
        <div tw=" flex flex-col items-center">
          <h2 tw="text-3xl leading-9 tracking-tight font-extrabold text-primary-600 sm:text-4xl sm:leading-10">
            Inspiring Stories
          </h2>
        </div>
        <div tw="mt-6 grid gap-16 border-primary-600 pt-10 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <StoryCard />
        </div>
      </div>
    </div>
  );
};

export default StorySection;
