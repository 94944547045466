import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';
import React, { useContext } from 'react';
import ContentFeatureSingle from '@leuven2030/framework/Content/ContentFeatureSingle';

const ContentIntro = () => {
  const {
    prismic: {
      homepage: {
        introduction,
        introduction_title,
        introduction_image,
        introduction_link
      }
    }
  } = useContext(PageContext);

  return (
    <ContentFeatureSingle
      tw=" py-12 bg-white"
      title={introduction_title}
      text={introduction}
      image={introduction_image.url}
      link={introduction_link}
    />
  );
};

export default ContentIntro;
