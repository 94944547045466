import NewsSubscribe from '@leuven2030/framework/News/NewsSubscribe';
import 'twin.macro';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import ContentSectionTitle from '@leuven2030/framework/Content/ContentSectionTitle';

const NewsHeader = (props) => {
  const {
    prismic: {
      news_page: { title, description }
    }
  } = useContext(PageContext);

  return (
    <div tw="space-y-3" {...props}>
      <ContentSectionTitle title={title} />
      <div tw="w-full flex flex-col space-y-3 sm:( flex-row items-start justify-between space-x-3 space-y-0)">
        <p tw="text-lg text-gray-500 lowercase">{description}</p>
        <NewsSubscribe tw="flex  lg:mt-0 lg:justify-end" color="primary" />
      </div>
    </div>
  );
};

export default NewsHeader;
