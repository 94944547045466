import 'twin.macro';
import React, { useContext } from 'react';
import ImageURL from '@leuven2030/framework/Image/ImageURL';
import Card from '@leuven2030/framework/Card/Card';
import CardImage from '@leuven2030/framework/Card/CardImage';
import CardTitle from '@leuven2030/framework/Card/CardTitle';
import { PageContext } from '@leuven2030/framework/Page/Page';
const PartnerCard = ({ cover, title, logo, _meta, ...props }) => {
  function getLogoUrl() {
    if (!logo) {
      return;
    }
    return ImageURL(logo.url, {
      w: 160,
      h: 160
    });
  }
  const {
    prismic: {
      layout: { read_more }
    }
  } = useContext(PageContext);
  return (
    <Card prismicMeta={_meta} tw="justify-between">
      <div tw="p-3">
        <CardTitle text={title} />
      </div>
      {!!cover && (
        <CardImage
          src={cover.url}
          imgixParams={{
            crop: 'faces',
            'mark-align': 'center,middle',
            'mark-pad': 80,
            mark64: getLogoUrl(),
            duotone: '5D5D5D,5D5D5D',
            'mark-fit': 'max',
            'duotone-alpha': 30
          }}
        />
      )}
      <div tw="px-3">
        <div tw="flex justify-end p-3">
          <span tw="text-primary-600">{read_more}</span>
        </div>
      </div>
    </Card>
  );
};

export default PartnerCard;
