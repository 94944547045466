import PageProps from '@leuven2030/school/Page/PageProps';

import ContentHero from '@leuven2030/school/Content/ContentHero';
import PartnerGrid from '@leuven2030/framework/Partner/PartnerGrid';
import React, { useContext } from 'react';
import 'twin.macro';
import ContentIntro from '@leuven2030/school/Content/ContentIntro';
import StorySection from '@leuven2030/framework/Story/StorySection';
import NewsSection from '@leuven2030/framework/News/NewsSection';
import Page, { PageContext } from '@leuven2030/framework/Page/Page';
import ContentHighlight from '@leuven2030/framework/Content/ContentHighlight';
import get from 'lodash/get';
import PageLayout from '@leuven2030/school/Page/PageLayout';
import { FoundingPartnersQuery } from '@leuven2030/framework/Partner/PartnersQuery';
import { gql } from '@apollo/client';
import { NewsPageQueryContent } from '@leuven2030/framework/News/NewsPageQuery';

const HomePage = Page(() => {
  const { prismic } = useContext(PageContext);
  const highlights = get(prismic, 'homepage.highlights');

  return (
    <PageLayout tw="mx-auto w-full flex flex-col bg-gray-50">
      <ContentHero />
      <ContentIntro />
      <NewsSection tw="bg-gray-100 py-16" />
      <StorySection />
      <ContentHighlight items={highlights} />
      <PartnerGrid />
    </PageLayout>
  );
});

export const getStaticProps = PageProps({
  prismic: {
    query: gql`
            query {
                homepage(uid: "homepage", lang: "nl-be") {
                    banner_actions{
                        banner_action_link {
                            _linkType
                            ... on Charter_dialog{
                                _meta{
                                    uid
                                    type
                                }
                            }
                            ... on Timeline{
                                _meta{
                                    uid
                                    type
                                }
                            }
                            ... on _FileLink{
                                url
                            }
                        }
                        banner_action_label
                    }
                    banner_heading
                    banner_image {
                        ... on _ImageLink {
                            url
                        }
                    }
                    introduction
                    introduction_title
                    introduction_image {
                        ... on _ImageLink {
                            url
                        }
                    }
                    introduction_link{
                        ...SEOFragment
                    }
                    highlights {
                        highlight_title
                        highlight_image {
                            ... on _ImageLink {
                                url
                            }
                        }
                        highlight_action_link {
                            ...SEOFragment
                        }
                    }
                }
                ${NewsPageQueryContent}
                ${FoundingPartnersQuery}
            }
        `
  }
});

const SchoolHomePage = (props) => <HomePage {...props} />;

export default SchoolHomePage;
