import 'twin.macro';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import { StyleContent } from '@leuven2030/framework/styles/general';
import PartnerCard from '@leuven2030/framework/Partner/PartnerCard';

const PartnerGrid = () => {
  const {
    prismic: {
      partners_page: { founding_partners_title },
      allPartners: { edges }
    }
  } = useContext(PageContext);
  return (
    <div tw="bg-gray-100">
      <div tw="py-12 lg:py-16 space-y-12 flex flex-col " css={StyleContent}>
        <p tw="text-center text-2xl leading-6 uppercase text-primary-600 tracking-wider">
          {founding_partners_title}
        </p>
        <div tw="grid grid-cols-2 md:grid-cols-3 lg:mt-8 gap-3 ">
          {edges.map(({ node }, index) => {
            return <PartnerCard tw="hover:bg-gray-600" key={index} {...node} />;
          })}
        </div>
      </div>
    </div>
  );
};
export default PartnerGrid;
