import { StyleContent } from '@leuven2030/framework/styles/general';
import ContentSectionTitle from '@leuven2030/framework/Content/ContentSectionTitle';
import Image from '@leuven2030/framework/Image/Image';
import PageLink from '@leuven2030/framework/Page/PageLink';
import React from 'react';
import 'twin.macro';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
const ContentFeatureSingle = ({ title, text, link, image, ...props }) => {
  return (
    <div tw=" py-12 " {...props}>
      <div tw=" space-y-6 " css={StyleContent}>
        <ContentSectionTitle title={title} />
        <div tw="flex-col-reverse flex sm:(flex-row space-x-12 space-y-0! items-stretch)">
          <div tw="flex-1 space-y-3">
            <PrismicText tw="prose-sm" text={text} />
            <PageLink {...link} variant="outlined" />
          </div>
          <div tw="flex-1 flex flex-col justify-between items-end space-y-6 mb-6 sm:mb-0">
            <Image
              tw="w-full h-auto shadow-2xl"
              src={image}
              width={800}
              height={800}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentFeatureSingle;
