import 'twin.macro';
import React from 'react';

const ContentSectionTitle = ({ title, ...props }) => {
  return (
    <h2
      tw="text-2xl leading-9 tracking-tight font-extrabold text-primary-600 sm:text-3xl sm:leading-10"
      {...props}
    >
      {title}
    </h2>
  );
};

export default ContentSectionTitle;
