import 'twin.macro';
import { StyleContent } from '@leuven2030/framework/styles/general';
import ContentHighlightItem from '@leuven2030/framework/Content/ContentHighlightItem';

const ContentHighlight = ({ items }) => {
  return (
    <div tw=" z-10 bg-gray-50 ">
      <div tw=" py-16" css={StyleContent}>
        <div tw="grid gap-5  lg:grid-cols-3 sm:grid-cols-2  ">
          {items.map((item) => (
            <ContentHighlightItem key={item.highlight_title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentHighlight;
